<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddSpecialDiscount"
      :is-valid="formValid">
       <div>
        <div>
          <p>{{$t('SPECIALDISCOUNT.ADD_SPECIALDISCOUNT.HEADER.TEXT')}}</p>
          <div class="default-input clearfix align-items-center mb-3">
            <vs-input :label="$t('SPECIALDISCOUNT.ADD_SPECIALDISCOUNT.NAME')"  class="mr-3 inputx" :placeholder="$t('SPECIALDISCOUNT.PLACEHOLDER.ADD_SPECIALDISCOUNT.NAME')"  v-model="data.name" :danger="name_fail" val-icon-danger="clear"/>
          </div>         
          <div class="default-input clearfix align-items-center mb-3">
            <div  class="float-left mr-2">
              <vs-input-number v-model="data.discount_value" min="0" step="0.5" max="80" :label="$t('SPECIALDISCOUNT.ADD.LABEL.DISCOUNT')" class="inputx" />
            </div>
          </div>   
        </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';

export default {
  name: "AddSpecialDiscountDlg",
  props:[],
  components:{
  },
  data: function (){
     return {
      dlgActive:false,
      popupTitle:this.$t('SPECIALDISCOUNT.ADD_SPECIALDISCOUNT.TITLE'),
      name_fail:false,
      name_ok:false,
      data:this.GetEmptyData()
     }
  }, 
  mounted () {
    this.ClearForm();
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      ShowDlg(){
        this.ClearForm();
        this.dlgActive=true;
        this.popupTitle = this.$t('SPECIALDISCOUNT.ADD_SPECIALDISCOUNT.TITLE');
        
      },
      closed(){
        this.ClearForm();
      },
      InitDlgData(data){
        
        if(this.IsNotNull(data))
        {
          this.ClearForm();
          this.popupTitle = this.$t('SPECIALDISCOUNT.ADD_SPECIALDISCOUNT.TITLE.EDIT');
          
          this.data = JSON.parse(JSON.stringify(data));

          this.dlgActive=true;
        }
      },
      accepted(){

          this.$store.dispatch('specialdiscount/saveSpecialDiscount', this.data)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('SPECIALDISCOUNT.ADD_SPECIALDISCOUNT.SUCCESS.SAVE')});  
                this.$emit('Success',response.id);
            }
            else
            {
              var text = this.$t(response.text);
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('SPECIALDISCOUNT.ADD_SPECIALDISCOUNT.WARNING.SAVE').replace("*1*",text)}); 
            }
            
          }, 
          error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('SPECIALDISCOUNT.ADD_SPECIALDISCOUNT.ERROR.SAVE').replace("*1*",error)});  
          });

          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      ClearForm(){ 
        this.data=this.GetEmptyData();
      },
      GetEmptyData(){
        return {
          id:0,
          name:"",
          discount_value:10,
          company_fk:0
        };
      }
  },
  computed:{
    formValid(){
      return this.data.discount_value > 0 && this.data.name.length > 0;
    },
  },
  watch:{
  }
};
</script>
<style>
div#promptAddSpecialDiscount > div.vs-dialog{
  max-width: 500px !important;
}
div#promptAddSpecialDiscount > div.vs-dialog > 
div.vs-dialog-text > div > div > 
div.vs-tabs > div.con-slot-tabs > div.vs-tabs--content{
min-height: 450px;
}
</style>